import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const egyediFejlesztes1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Egyedi fejlesztésekkel tud egyedi szolgáltatást nyújtani vevői számára",
  description: "Mert új fejlesztésekkel, folyamatok optimalizálásával, rendszerek összekötésével munkaerőt tud felszabadítani, munkaórákat tud megspórolni, versenyelőnyre tud szert tenni",
  displayXo: true,
  reverse: false,
  title: "Miért kellenek egyedi fejlesztések?",
  picture: "/images/services/egyedi_card_1.jpg"
}

export const egyediFejlesztesekInfoCards = [
  egyediFejlesztes1,
  
]