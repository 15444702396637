import * as React from "react"
import Layout from "../components/layout"
import ServiceTop from "../components/service-page/service-top"
import SkillsSlider from "../components/skills-slider/skills-slider"
import ServiceInfoCards from "../components/service-page/service-info-cards"
import { egyediFejlesztesek } from "../interfaces/service/services/egyedi-fejlesztesek"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import PageBottom from "../components/page-bottom/page-bottom"

const EgyediFejlesztesekPage = (props) => {
  return <Layout subHeader={false}>
    <MetaData title={'Egyedi fejlesztések'} url={props.location.href} description={"Van egy ötleted? Ne várj, vedd fel velünk a kapcsolatot, mert egyedi fejlesztésekkel tudsz egyedi szolgáltatást nyújtani vevőid részére."}/>
    <StructuredData title={'Egyedi fejlesztések'} url={props.location.href} description={"Van egy ötleted? Ne várj, vedd fel velünk a kapcsolatot, mert egyedi fejlesztésekkel tudsz egyedi szolgáltatást nyújtani vevőid részére."}/>
    <ServiceTop service={egyediFejlesztesek} />
    <SkillsSlider />
    <ServiceInfoCards infoCards={egyediFejlesztesek.cards} />
    <PageBottom/>
  </Layout>
}

export default EgyediFejlesztesekPage
