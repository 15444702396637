import { Service } from "../service-interface"
import { egyediFejlesztesekInfoCards } from "../../service-info-card-interface/used_by/egyedi-fejlesztesek-cards"

export const egyediFejlesztesek : Service = {
  title: 'Egyedi fejlesztések',
  description: "Van egy ötleted? Ne várj, vedd fel velünk a kapcsolatot és megmondjuk hogyan tudjuk a leghatékonyabban megvalósítani számodra. Egyedi fejlesztésekkel tudsz egyedi szolgáltatást nyújtani vevőid részére.",
  icon: "/images/bulb.svg",
  topimg: "/images/egyedi_fejlesztesek.png",
  cards: egyediFejlesztesekInfoCards,
  listPoints: [
    "Egy vállalatirányítási rendszerrel kell összekötni a webáruházat?",
    "Egy bank vagy hitelintézet online fizetési rendszerét kell integrálni?",
  ]
}
